<template src="./login.html"></template>
<style scoped src="./login.css"></style>


<script>
import { required, email } from 'vuelidate/lib/validators'
import ButtonSpinner from 'vue-button-spinner'
import applicationConstants from '@/constants/AppConstants.vue'
import AuthenticationService from '@/services/AuthenticationService.vue'
import { mapActions, mapGetters } from 'vuex'
export default {
    data(){
        return {
            loginCredentials : {
                username: '',
                password: '',
            },           
            isLoading: false,
            status: '',
            errorMessages: applicationConstants.formsMessages,
            loginFailedMessage : ''
        }
    },
    validations : {
        loginCredentials : {
            username : {
                email,
                required
            },
            password : {
                required
            }
        }       
    },
    methods : {
        ...mapActions(['reserveUserName', 'fetchUserAcl', 'fetchUserDetails', 'fetchGroupDetails']),
        submitLoginHandler () {  
            if (this.$v.loginCredentials.$invalid) {
                this.$v.loginCredentials.$touch()
            } else {
                this.isLoading = true
                AuthenticationService.methods.login(this.loginCredentials)
                .then(async (res) => {
                    this.reserveUserName(res.csGetUserProfileResponse.FirstName._text + ' ' + res.csGetUserProfileResponse.LastName._text)  
                    this.status = true
                    this.$session.start()
                    this.$session.set('userRefId', res.csGetUserProfileResponse.UserRefId._text)
                    this.$session.set('userEmail',res.csGetUserProfileResponse.EmailAddress._text)

                    // check user is activated or not
                    await this.fetchUserDetails(this.$session.get('userEmail'))
                    await this.fetchGroupDetails(await this.getUserDetails.GroupId)
                    if (await this.getUserDetails.IsActivated === "1" && await this.getGroupDetails.IsActivated === "1") {
                        if(this.$session.get('userEmail')) {
                            let enabledRoutes = []
                            await this.fetchUserAcl({
                                userEmail: this.$session.get('userEmail'),
                                groupId:  await this.getUserDetails.GroupId
                            })
                            localStorage.setItem('userAclRights', JSON.stringify(await this.getUserAclRights))
                            this.$router.options.routes.forEach(route => {
                                if(localStorage.getItem('userAclRights')) {
                                    if( (localStorage.getItem('userAclRights')).includes(route.meta?.rightsCode)) {
                                        route.meta.access = true
                                        if(route.meta.rightsCode) enabledRoutes.push(route)
                                    } 
                                }
                            })
                            this.$store.commit('storeEnabledRoutes', enabledRoutes) 
                        }
                        this.redirectAfterSuccessfullLogin()
                    } else {
                        this.loginFailedMessage = 'User is not Activated'
                        this.status = false
                    }
                    this.isLoading = false
                })
                .catch( err => {
                    this.isLoading = false
                    this.status = false  
                    this.loginFailedMessage = err                 
                })
            }
        },
        onInput(event){
            switch (event.target.name) {
                case 'Email':
                    this.$v.loginCredentials.username.$touch()
                    break
                case 'Password':
                    this.$v.loginCredentials.password.$touch()
                    break
            }
        },
        redirectAfterSuccessfullLogin () {
            this.$router.push({
                name: this.getNextRoute ?? 'ViewProfile'
            })
        }
    },
    components : {
        ButtonSpinner
    },
    computed : {
        ...mapGetters(['getUserAclRights', 'getUserDetails', 'getGroupDetails', 'getNextRoute'])
    }
}
</script>